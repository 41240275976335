import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function About({ data }) {
  return (
    <Layout>
      <SEO title={`About the NFL DFS Blog`} description={`About this website.`} />
      <h1>About {data.site.siteMetadata.title}</h1>
      <p>
        NFL DFS is a blog and info site created by passionate players of NFL Daily Fantasy Football. There are many voices out there, and a lot of content. Our goal is to provide our perspective on the industry and its trends, and to help people improve at playing DFS.
      </p>
    </Layout>
  )
}

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`